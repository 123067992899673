import React from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styled from 'styled-components';
import { dataLicense } from './licenseNumber';

const customStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
    backgroundColor: 'rgb(247, 247, 247, 0.5)',
  },
};

const StyledModal = styled(Modal)`
  width: 990px;
  &:focus {
    outline: none;
  }
`;

const ModalContainer = styled.div`
  position: relative;
  padding: 70px 88px 70px;
  background-color: #fff;
  border-radius: 12px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  height: 700px;
  max-height: 100%;
  ${({ theme }) => theme.phone`
    padding: 50px 20px;
    border-radius: 6px;
    top: 38%;
    width: calc(100% - 50px);
  `};
  ${({ theme }) => theme.phoneSmall`
    width: calc(100% - 20px);
  `};

  &:focus {
    outline: none;
  }
`;

const StyledIconWrapper = styled.button`
  position: absolute;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  top: 30px;
  right: 26px;
  i {
    color: #1d253c;
  }
`;

const StyledContentTable = styled.div`
  max-height: 100%;
  overflow: auto;
  table,
  td,
  th {
    padding: 22px;
    ${({ theme }) => theme.phone`
      padding: 15px 0px 15px 0px;
      font-size: 16px;
      line-height: 24px;
    `};
    font-size: 22px;
    line-height: 28px;
    text-align: start;
  }

  th {
    font-family: 'Bold';
    text-align: start;
    font-size: 22px;
    line-height: 28px;
    border-bottom: 1pt solid #c4c4c4;
    ${({ theme }) => theme.phone`
      font-size: 16px;
      line-height: 22px;
    `};
  }

  tr {
    border-bottom: 1pt solid #c4c4c4;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  ${({ theme }) => theme.phone`
    margin-top: 15px;
    padding: 0px 20px 0px 0px;
  `};
`;

const ModalTable = ({ isModalOpen, closeModal }) => (
  <StyledModal
    onRequestClose={closeModal}
    style={customStyles}
    isOpen={isModalOpen}
  >
    <ModalContainer>
      <StyledIconWrapper
        onClick={() => {
          ReactGA.event({
            category: 'Shared',
            action: 'Close insurancematch window',
          });

          closeModal();
        }}
      >
        <i className="far fa-2x fa-times" />
      </StyledIconWrapper>
      <StyledContentTable>
        <StyledTable>
          <th>State</th>
          <th>License Number</th>
          <tbody>
            {dataLicense.map(data => (
              <tr>
                <td>{data.state}</td>
                <td>{data.license === null ? 'NA' : data.license}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </StyledContentTable>
    </ModalContainer>
  </StyledModal>
);

ModalTable.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

Modal.setAppElement('#__next');

export default ModalTable;
