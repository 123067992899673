export const dataLicense = [
  {
    state: 'Alabama',
    license: 3001664589,
  },
  {
    state: 'Alaska',
    license: 3001718975,
  },
  {
    state: 'Arizona',
    license: 3001664612,
  },
  {
    state: 'Arkansas',
    license: 3001664606,
  },
  {
    state: 'California',
    license: 6009417,
  },
  {
    state: 'Colorado',
    license: 725430,
  },
  {
    state: 'Connecticut',
    license: 3001664579,
  },
  {
    state: 'Delaware',
    license: 3001664626,
  },
  {
    state: 'Florida',
    license: null,
  },
  {
    state: 'Georgia',
    license: 222618,
  },
  {
    state: 'Hawaii',
    license: 549566,
  },
  {
    state: 'Idaho',
    license: 895561,
  },
  {
    state: 'Illinois',
    license: 3001664637,
  },
  {
    state: 'Indiana',
    license: 3752794,
  },
  {
    state: 'Iowa',
    license: 3001664531,
  },
  {
    state: 'Kansas',
    license: 823536053,
  },
  {
    state: 'Kentucky',
    license: 1173237,
  },
  {
    state: 'Louisiana',
    license: 963555,
  },
  {
    state: 'Maine',
    license: 'AGN404033',
  },
  {
    state: 'Maryland',
    license: 3001664620,
  },
  {
    state: 'Massachusetts',
    license: null,
  },
  {
    state: 'Michigan',
    license: 135573,
  },
  {
    state: 'Minnesota',
    license: 40775905,
  },
  {
    state: 'Mississippi',
    license: 15042355,
  },
  {
    state: 'Missouri',
    license: 3001773394,
  },
  {
    state: 'Montana',
    license: 3001664668,
  },
  {
    state: 'Nebraska',
    license: 3001664478,
  },
  {
    state: 'Nevada',
    license: 3752359,
  },
  {
    state: 'New Hampshire',
    license: 3001664623,
  },
  {
    state: 'New Jersey',
    license: 3001664471,
  },
  {
    state: 'New Mexico',
    license: 3001779713,
  },
  {
    state: 'New York',
    license: null,
  },
  {
    state: 'North Carolina',
    license: 3001664571,
  },
  {
    state: 'North Dakota',
    license: 3001664402,
  },
  {
    state: 'Ohio',
    license: 1412055,
  },
  {
    state: 'Oklahoma',
    license: 3001664428,
  },
  {
    state: 'Oregon',
    license: 3001683289,
  },
  {
    state: 'Pennsylvania',
    license: 1046252,
  },
  {
    state: 'Rhode Island',
    license: 3001664421,
  },
  {
    state: 'South Carolina',
    license: 3001664447,
  },
  {
    state: 'South Dakota',
    license: 10028946,
  },
  {
    state: 'Tennessee',
    license: 3001664540,
  },
  {
    state: 'Texas',
    license: 2756525,
  },
  {
    state: 'Utah',
    license: 896517,
  },
  {
    state: 'Vermont',
    license: 3001664552,
  },
  {
    state: 'Virginia',
    license: 152738,
  },
  {
    state: 'Washington',
    license: 1137606,
  },
  {
    state: 'West Virginia',
    license: 3001666073,
  },
  {
    state: 'Wisconsin',
    license: 3001677694,
  },
  {
    state: 'Wyoming',
    license: 488742,
  },
  {
    state: 'District of Columbia',
    license: 3001664595,
  },
];
