/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Link from 'next/link';
import SVG from 'react-inlinesvg';
import { useAppContext } from '../../store';
import ModalTable from './ModalTable/ModalTable';

const largeDesktopMargin = '72px';
const smallDesktopMargin = '45px';

const FooterWrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.dark};
  padding: 100px 0;
  ${({ theme }) => theme.desktopSmall`padding: 100px 16px`};
  ${({ theme }) => theme.tablet`padding: 10px 15px 40px;`};
  ${({ isBlack }) =>
    isBlack &&
    css`
      background-color: #000;
    `};
  ${({ isStoryBlock }) =>
    isStoryBlock &&
    css`
      background-color: #fff;
    `};
`;

const Container = styled.div`
  max-width: 1260px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  ${({ theme }) => theme.tablet`
    flex-direction: column;
    margin-top: 49px;
  `};
  ${({ theme }) => theme.phone`margin-top: 49px;`};
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > svg {
    width: 150px;
    height: 30px;
    path {
      color: #fff;
    }
  }
  ${({ theme }) => theme.phone`margin-right: 16px;`};
`;

const LinksContainer = styled.div`
  display: flex;
  ${({ theme }) => theme.tablet`margin-bottom: 34px;`};
`;

const StyledTableLink = styled.span`
  color: rgb(0, 147, 233);
  text-decoration: underline;
  cursor: pointer;
`;

const TextContainer = styled.div`
  border: 1px solid rgba(212, 212, 212, 0.3);
  border-width: 0 1px;
  max-width: 570px;
  width: 100%;
  padding: 0 ${largeDesktopMargin};

  ${({ theme }) => theme.desktopSmall`padding: 0 ${smallDesktopMargin};`};
  @media (max-width: 1160px) {
    max-width: 400px;
  }
  ${({ theme }) => theme.tablet`
    max-width: 100%;
    padding: 40px 0;
    border-width: 1px 0;
  `};
`;

const SubContainer = styled.div`
  margin: 19px 0 0 ${largeDesktopMargin};

  ${({ theme }) => theme.desktopSmall`margin: 19px 0 0 ${smallDesktopMargin};`};
  ${({ theme }) => theme.tablet`margin: 40px 0 0 0;`};
`;

const StyledLink = styled.a`
  font-family: 'Light';
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledFooterText = styled.p`
  font-family: 'Light';
  color: ${({ theme }) => theme.colors.white};
  font-size: 11px;
  line-height: 12px;
  ${({ theme }) => theme.phone`font-size: 11px;`};

  ${({ isLabsText }) =>
    isLabsText &&
    css`
      @media (min-width: 1260px) {
        padding-right: 13px;
      }
    `};
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `};

  ${({ tabletMarginTop, theme }) =>
    tabletMarginTop &&
    css`
      ${theme.tablet`margin-top: ${tabletMarginTop};`};
    `};

  ${({ mobileMarginTop, theme }) =>
    mobileMarginTop &&
    css`
      ${theme.phone`margin-top: ${mobileMarginTop};`};
    `};

  ${({ small, theme }) =>
    small &&
    css`
      font-size: 10px;
      line-height: 14px;
      ${theme.phone`font-size: 9px;`};
    `};

  ${({ isStoryBlock }) =>
    isStoryBlock &&
    css`
      color #1A2136;
      font-size: 20px;
      padding: 0px 40px 0px 40px;
      margin-top: -15px;
    `};
`;

const StyledFooterTextPivot = styled.p`
  font-family: 'Light';
  color: ${({ theme }) => theme.colors.white};
  font-size: 12px;
  line-height: 16px;

  ${({ isLabsText }) =>
    isLabsText &&
    css`
      @media (min-width: 1260px) {
        padding-right: 13px;
      }
    `};
  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${marginTop};
    `};

  ${({ tabletMarginTop, theme }) =>
    tabletMarginTop &&
    css`
      ${theme.tablet`margin-top: ${tabletMarginTop};`};
    `};

  ${({ mobileMarginTop, theme }) =>
    mobileMarginTop &&
    css`
      ${theme.phone`margin-top: ${mobileMarginTop};`};
    `};

  ${({ small, theme }) =>
    small &&
    css`
      font-size: 10px;
      line-height: 14px;
      ${theme.phone`
    font-size: 12px;
    margin-left: -14px;
    `};
    `};

  ${({ isStoryBlock }) =>
    isStoryBlock &&
    css`
    color #1A2136;
    font-size: 20px;
    padding: 0px 40px 0px 40px;
    margin-top: -15px;
  `};
`;

const StyledIcon = styled.span`
  margin-left: 3px;
`;

const renderLinks = items => {
  const ListWrapper = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 40px;
    margin: 0 ${largeDesktopMargin};
    font-size: 14px;
    ${({ theme }) => theme.phone`font-size: 12px;`};

    ${({ theme }) => theme.tablet`
      margin: 0 0 0 auto;
      grid-gap: 0 40px;
      `};

    ${({ theme }) => theme.phone`
      margin: 8px 0 0 auto;
      grid-gap: 0 20px;
    `};
  `;

  const StyledTextLink = styled.p`
    font-family: 'Light';
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-size: 14px;
    ${({ theme }) => theme.phone`font-size: 12px;`};
  `;

  return (
    <ListWrapper>
      {items.map(
        item =>
          !(
            item.link === 'findmesavings' ||
            item.link === 'getquotes' ||
            item.link === 'namethedog'
          ) && (
            <li key={item.link}>
              {item.link === 'faq' ? (
                <StyledLink
                  data-test-id="faqFooter"
                  href="http://intercom.help/keller-covered/faq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <a
                    onClick={() => {
                      if (item.tag) {
                        ReactGA.event({
                          category: 'Shared',
                          action: item.tag,
                        });
                      }
                    }}
                  >
                    {item.label}
                  </a>
                </StyledLink>
              ) : (
                <Link href={`/${item.link}`}>
                  <StyledTextLink data-test-id={`${item.link}Footer`}>
                    <a
                      onClick={() => {
                        if (item.tag) {
                          ReactGA.event({
                            category: 'Shared',
                            action: item.tag,
                          });
                        }
                      }}
                    >
                      {item.label}
                    </a>
                  </StyledTextLink>
                </Link>
              )}
            </li>
          )
      )}
    </ListWrapper>
  );
};

const ContainerDivFooterPivot = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 40px 0px 40px;
  ${({ small }) =>
    small &&
    css`
      padding: 0px 12px 0px 10px;
    `};
`;

const LogoContainerPivot = styled.div`
  display: flex;
  ${({ small, theme }) =>
    small &&
    css`
      ${theme.phone`
> svg {
  width: 80px;
  height: 50px;
  }
`};
    `};
`;

const Footer = ({ linkItems, isBlack, isStoryBlock }) => {
  const currentYear = new Date().getFullYear();
  const { intl } = useAppContext();
  const [isMobileView, setIsMobileView] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const checkIfMobile = () => {
    const isMobile = window.innerWidth < 900;

    setIsMobileView(isMobile);
  };

  useEffect(() => {
    checkIfMobile();

    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const updateSvgStyle = code => {
    const codeWithReplacedFill = code.replace(
      /fill=".*?"/g,
      'fill="currentColor"'
    );
    const codeWithReplacedMask = codeWithReplacedFill.replace(
      /mask=".*?"/g,
      'mask=""'
    );
    return codeWithReplacedMask;
  };

  const renderIcons = () => {
    const icons = [
      {
        icon: 'facebookFooter',
        href: 'https://www.facebook.com/KellerCovered/',
        tag: 'Click on Facebook link in footer',
      },
      {
        icon: 'twitter',
        href: 'https://twitter.com/kwri',
        tag: 'Click on Twitter link in footer',
      },
      {
        icon: 'linkedin',
        href: 'https://www.linkedin.com/company/keller-williams-realty-inc/',
        tag: 'Click on Linkedin link in footer',
      },
    ];
    const StyledIconsContainer = styled.div`
      display: flex;

      > * + * {
        margin-left: 20px;
      }

      ${({ theme }) => theme.tablet`
        margin-top: 16px;
      `};
      ${({ theme }) => theme.phone`
        margin-top: 19px;
      `};
    `;

    return (
      <StyledIconsContainer>
        {icons.map(el => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            key={el.icon}
            href={el.href}
            onClick={() => {
              ReactGA.event({
                category: 'Shared',
                action: el.tag,
              });
            }}
          >
            <SVG src={`/static/img/${el.icon}.svg`} />
          </a>
        ))}
      </StyledIconsContainer>
    );
  };

  const openModalTable = () => {
    setIsModalVisible(true);
  };

  const closeModalTable = () => {
    setIsModalVisible(false);
  };

  return (
    <FooterWrapper isBlack={isBlack} isStoryBlock={isStoryBlock}>
      {isStoryBlock && (
        <>
          <ContainerDivFooterPivot small>
            <div style={{ display: 'flex' }}>
              <LogoContainerPivot small>
                {' '}
                <SVG src="/static/img/foot1story.svg" />
              </LogoContainerPivot>
              {!isMobileView && (
                <div>
                  {' '}
                  <SVG src="/static/img/foot2story.svg" />
                </div>
              )}
            </div>
            <LogoContainerPivot small>
              {' '}
              <SVG src="/static/img/foot3story.svg" />
            </LogoContainerPivot>
            <LogoContainerPivot small>
              {' '}
              <SVG src="/static/img/foot4story.svg" />
            </LogoContainerPivot>
          </ContainerDivFooterPivot>
          <StyledFooterTextPivot
            small
            marginTop="16px"
            mobileMarginTop="19px"
            isStoryBlock={isStoryBlock}
          >
            Copyright &copy; 2018-
            {currentYear}
            &nbsp; Keller Mortgage and Keller Covered
          </StyledFooterTextPivot>
        </>
      )}
      {!isStoryBlock && (
        <Container isBlack={isBlack}>
          <LinksContainer>
            <LogoContainer>
              <Link href="/">
                <a
                  onClick={() => {
                    ReactGA.event({
                      category: 'Shared',
                      action: 'Click logo footer',
                    });
                  }}
                >
                  <SVG src="/static/img/whiteLogoKW.svg" />
                </a>
              </Link>
              {renderIcons()}
              <StyledFooterText small marginTop="16px" mobileMarginTop="19px">
                Copyright &copy; 2018-
                {currentYear}
                &nbsp; Keller Covered
              </StyledFooterText>
            </LogoContainer>
            {renderLinks(linkItems)}
          </LinksContainer>
          <TextContainer>
            <StyledFooterText isLabsText mobileMarginTop="19px">
              {intl.get('app.footer.page')}
            </StyledFooterText>
            <StyledFooterText
              isLabsText
              marginTop="16px"
              mobileMarginTop="19px"
            >
              {intl.get('app.footer.page2')}
            </StyledFooterText>
            <StyledFooterText
              isLabsText
              marginTop="16px"
              mobileMarginTop="19px"
            >
              {intl.getHTML('app.footer.page3')}
              <StyledTableLink onClick={openModalTable}>here.</StyledTableLink>
            </StyledFooterText>
          </TextContainer>
          <SubContainer>
            <SVG src="/static/img/footerKW.svg" />
            <StyledFooterText marginTop="10px" mobileMarginTop="14px">
              {intl.get('app.footer.kw.direction')}
              <StyledLink
                href="https://www.kw.com/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  ReactGA.event({
                    category: 'Shared',
                    action: 'Click on Visit KW in footer',
                  });
                }}
              >
                www.kw.com
              </StyledLink>
            </StyledFooterText>
          </SubContainer>
        </Container>
      )}
      <ModalTable isModalOpen={isModalVisible} closeModal={closeModalTable} />
    </FooterWrapper>
  );
};

Footer.propTypes = {
  linkItems: PropTypes.any.isRequired,
  isBlack: PropTypes.bool,
  isStoryBlock: PropTypes.bool,
};

Footer.defaultProps = {
  isBlack: false,
  isStoryBlock: false,
};

export default Footer;
